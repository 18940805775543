import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer adds a product to basket we are going to create a basket
// This basket is specific to a customer supplier relationsship.
// We use a placeholder listing to create the basket ansd then add actual items ans line items.
export const TRANSITION_CREATE_NEW_ACTIVE_BASKET = 'transition/create-new-active-basket';

// A customer can add many line items.
export const TRANSITION_ADD_LINE_ITEM_TO_ACTIVE_BASKET =
  'transition/add-line-item-to-active-basket';

export const TRANSITION_UPDATE_LINE_ITEM_QUANTITY_IN_ACTIVE_BASKET =
  'transition/update-line-item-quantity-in-active-basket';

// A customer can optionally add purchase order number.
export const TRANSITION_ADD_PURCHASE_ORDER_NUMBER = 'transition/add-purchase-order-number';

// A suppleir can optionally add invoice number and file.
export const TRANSITION_ADD_INVOICE_NUMBER_AND_FILE = 'transition/add-invoice-number-and-file';

// A suppleir can optionally add invoice number and file.
export const TRANSITION_ADD_TRANSPORT_DETAILS = 'transition/add-transport-details';

// A provir can optionally add delivery date.
export const TRANSITION_ADD_DELIVERY_DATE = 'transition/add-delivery-date';
export const TRANSITION_CUSTOMER_ADD_DELIVERY_DATE = 'transition/customer-add-delivery-date';

// A provir can optionally add supplier order number.
export const TRANSITION_ADD_SUPPLIER_ORDER_NUMBER = 'transition/add-supplier-order-number';

// A customer can start the purchase without payment needed
export const TRANSITION_PURCHASED_PAYMENT_BY_INVOICE = 'transition/purchased-payment-by-invoice';

// System validates and accepts the order then send to supplier.
export const TRANSITION_SYSTEM_ACCEPT_ORDER = 'transition/system-accept-order';
export const TRANSITION_SUBMIT_TO_SUPPLIER = 'transition/submit-to-supplier';

// Supplier steps in the transaction
export const TRANSITION_SUPPLIER_ACCEPTS_ORDER = 'transition/supplier-accepts-order';
export const TRANSITION_SUPPLIER_ACCEPTS_ORDER_WITHOUT_NOTIFICATION =
  'transition/supplier-accepts-order-without-notification';

export const TRANSITION_PLACE_ON_HOLD = 'transition/place-on-hold';
export const TRANSITION_REMOVE_ON_HOLD = 'transition/remove-on-hold';

export const TRANSITION_CANCELLED_FROM_SUBMITTED_TO_SUPPLIER =
  'transition/cancel-from-submitted-to-supplier';
export const TRANSITION_CANCELLED_FROM_ACCEPTED_BY_SUPPLIER =
  'transition/cancel-from-accepted-by-supplier';
export const TRANSITION_CANCELLED_FROM_ON_HOLD = 'transition/cancel-from-on-hold';

export const TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_ONECLICK =
  'transition/supplier-accepts-order-via-oneclick';
export const TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_OPERATOR =
  'transition/supplier-accepts-order-via-operator';

export const TRANSITION_UPDATE_LINE_ITEM_PICKED_DETAILS =
  'transition/update-line-item-picked-details';

export const TRANSITION_MARK_AS_PICKED = 'transition/mark-as-picked';
export const TRANSITION_GO_BACK_TO_ACCEPTED = 'transition/go-back-to-accepted';
export const TRANSITION_GO_BACK_TO_SUBMITTED = 'transition/go-back-to-submitted';
export const TRANSITION_GO_BACK_TO_READY_FOR_DISPATCH = 'transition/go-back-to-ready-for-dispatch';
export const TRANSITION_MARK_AS_DISPATCHED = 'transition/mark-as-dispatched';
export const TRANSITION_SUPLIER_REQUESTS_HAULAGE = 'transition/supplier-send-to-haulier';
export const TRANSITION_HAULIER_ACCEPTS_REQUEST_VIA_OPERATOR =
  'transition/haulier-accepts-order-via-oneclick-by-operator';
export const TRANSITION_MARK_AS_DELIVERED = 'transition/mark-as-delivered';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_BASKET_READY = 'basket-ready';
const STATE_SUBMITTED_FOR_VALIDATION = 'submitted-for-validation';
const STATE_ACCEPTED_BY_SYSTEM = 'accepted-by-system';
const STATE_SUBMITTED_TO_SUPPLIER = 'submitted-to-supplier';
const STATE_ON_HOLD = 'on-hold';
const STATE_CANCELLED_BY_SUPPLIER = 'cancelled-by-supplier';
const STATE_ACCEPTED_BY_SUPPLIER = 'accepted-by-supplier';
const STATE_READY_FOR_DISPATCH = 'ready-for-dispatch';
const STATE_DISPATCHED = 'dispatched';
const STATE_SUBMITTED_TO_HAULIER = 'submitted-to-haulier';
const STATE_DELIVERED = 'delivered';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'payment-by-invoice/payment-by-invoice',

  //  flex-product-default-process/release-1

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_CREATE_NEW_ACTIVE_BASKET]: STATE_BASKET_READY,
      },
      restricted: 'buyer',
    },
    [STATE_BASKET_READY]: {
      on: {
        [TRANSITION_CREATE_NEW_ACTIVE_BASKET]: STATE_BASKET_READY,
        [TRANSITION_ADD_LINE_ITEM_TO_ACTIVE_BASKET]: STATE_BASKET_READY,
        [TRANSITION_UPDATE_LINE_ITEM_QUANTITY_IN_ACTIVE_BASKET]: STATE_BASKET_READY,
        [TRANSITION_ADD_PURCHASE_ORDER_NUMBER]: STATE_BASKET_READY,
        [TRANSITION_PURCHASED_PAYMENT_BY_INVOICE]: STATE_SUBMITTED_FOR_VALIDATION,
        [TRANSITION_CUSTOMER_ADD_DELIVERY_DATE]: STATE_BASKET_READY,
      },
      restricted: 'buyer',
    },
    [STATE_SUBMITTED_FOR_VALIDATION]: {
      on: {
        [TRANSITION_SYSTEM_ACCEPT_ORDER]: STATE_ACCEPTED_BY_SYSTEM,
      },
    },
    [STATE_ACCEPTED_BY_SYSTEM]: {
      on: {
        [TRANSITION_SUBMIT_TO_SUPPLIER]: STATE_SUBMITTED_TO_SUPPLIER,
      },
    },
    [STATE_SUBMITTED_TO_SUPPLIER]: {
      on: {
        [TRANSITION_SUPPLIER_ACCEPTS_ORDER]: STATE_ACCEPTED_BY_SUPPLIER,
        [TRANSITION_SUPPLIER_ACCEPTS_ORDER_WITHOUT_NOTIFICATION]: STATE_ACCEPTED_BY_SUPPLIER,
        [TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_OPERATOR]: STATE_ACCEPTED_BY_SUPPLIER,
        [TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_ONECLICK]: STATE_ACCEPTED_BY_SUPPLIER,
        [TRANSITION_PLACE_ON_HOLD]: STATE_ON_HOLD,
        [TRANSITION_CANCELLED_FROM_SUBMITTED_TO_SUPPLIER]: STATE_CANCELLED_BY_SUPPLIER,
        [TRANSITION_ADD_SUPPLIER_ORDER_NUMBER]: STATE_SUBMITTED_TO_SUPPLIER,
      },
    },

    [STATE_ON_HOLD]: {
      on: {
        [TRANSITION_REMOVE_ON_HOLD]: STATE_ACCEPTED_BY_SUPPLIER,
        [TRANSITION_CANCELLED_FROM_ON_HOLD]: STATE_CANCELLED_BY_SUPPLIER,
      },
    },
    [STATE_ACCEPTED_BY_SUPPLIER]: {
      on: {
        [TRANSITION_MARK_AS_PICKED]: STATE_READY_FOR_DISPATCH,
        [TRANSITION_UPDATE_LINE_ITEM_PICKED_DETAILS]: STATE_ACCEPTED_BY_SUPPLIER,
        [TRANSITION_ADD_DELIVERY_DATE]: STATE_ACCEPTED_BY_SUPPLIER,
        [TRANSITION_ADD_INVOICE_NUMBER_AND_FILE]: STATE_ACCEPTED_BY_SUPPLIER,
        [TRANSITION_GO_BACK_TO_SUBMITTED]: STATE_SUBMITTED_TO_SUPPLIER,
        [TRANSITION_CANCELLED_FROM_ACCEPTED_BY_SUPPLIER]: STATE_CANCELLED_BY_SUPPLIER,
      },
    },
    [STATE_READY_FOR_DISPATCH]: {
      on: {
        [TRANSITION_MARK_AS_DISPATCHED]: STATE_DISPATCHED,
        [TRANSITION_SUPLIER_REQUESTS_HAULAGE]: STATE_SUBMITTED_TO_HAULIER,

        [TRANSITION_ADD_TRANSPORT_DETAILS]: STATE_READY_FOR_DISPATCH,
        [TRANSITION_GO_BACK_TO_ACCEPTED]: STATE_ACCEPTED_BY_SUPPLIER,
      },
    },

    [STATE_SUBMITTED_TO_HAULIER]: {
      on: {
        [TRANSITION_HAULIER_ACCEPTS_REQUEST_VIA_OPERATOR]: STATE_DISPATCHED,
      },
    },

    [STATE_DISPATCHED]: {
      on: {
        [TRANSITION_MARK_AS_DELIVERED]: STATE_DELIVERED,
        [TRANSITION_GO_BACK_TO_READY_FOR_DISPATCH]: STATE_READY_FOR_DISPATCH,
      },
    },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Note: currently we assume that state description doesn't contain nested states.
const restrictedStatesFromStateDescription = (description, role) => {
  let states = {};
  for (const [key, value] of Object.entries(description.states)) {
    if (!value.restricted || role == value.restricted) {
      states[key] = value;
    }
  }
  return states;
};

// Get all the transitions from states object in an array
const getTransitions = (states, role = null) => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;

    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(
  statesFromStateDescription(stateDescription, 'supplier')
).map(t => t.key);

export const SUPPLIER_TRANSITIONS = getTransitions(
  restrictedStatesFromStateDescription(stateDescription, 'supplier')
).map(t => t.key);

export const BUYER_TRANSITIONS = getTransitions(
  restrictedStatesFromStateDescription(stateDescription, 'buyer')
).map(t => t.key);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_SUBMITTED_TO_SUPPLIER);

export const transitionsToOnhold = getTransitionsToState(STATE_ON_HOLD);

export const transitionsToCancelledBySupplier = getTransitionsToState(STATE_CANCELLED_BY_SUPPLIER);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsBasketReady = tx =>
  getTransitionsToState(STATE_BASKET_READY).includes(txLastTransition(tx));

export const txIsAwaitingSupplierApproval = tx => {
  if (
    getTransitionsToState(STATE_SUBMITTED_TO_SUPPLIER).includes(txLastTransition(tx)) ||
    getTransitionsToState(STATE_ACCEPTED_BY_SYSTEM).includes(txLastTransition(tx)) ||
    getTransitionsToState(STATE_SUBMITTED_FOR_VALIDATION).includes(txLastTransition(tx))
  ) {
    return true;
  }
};
export const txIsSupplierApprovedOrder = tx =>
  getTransitionsToState(STATE_ACCEPTED_BY_SUPPLIER).includes(txLastTransition(tx));

export const txIsAwaitingDispatch = tx =>
  getTransitionsToState(STATE_READY_FOR_DISPATCH).includes(txLastTransition(tx));

export const txIsAwaitingPick = tx =>
  getTransitionsToState(STATE_ACCEPTED_BY_SUPPLIER).includes(txLastTransition(tx));

export const txIsInEditableState = (tx, isProvider) =>
  isProvider
    ? [
      TRANSITION_SUPPLIER_ACCEPTS_ORDER,
      TRANSITION_SUPPLIER_ACCEPTS_ORDER_WITHOUT_NOTIFICATION,
        TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_ONECLICK,
        TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_OPERATOR,
        TRANSITION_UPDATE_LINE_ITEM_PICKED_DETAILS,
        TRANSITION_ADD_DELIVERY_DATE,
        TRANSITION_ADD_INVOICE_NUMBER_AND_FILE,
        TRANSITION_CUSTOMER_ADD_DELIVERY_DATE,
        TRANSITION_ADD_SUPPLIER_ORDER_NUMBER,
      ].includes(txLastTransition(tx))
    : false;

export const txIsInTransit = tx => {
  return getTransitionsToState(STATE_DISPATCHED).includes(txLastTransition(tx));
};

export const txIsAwaitingHaulier = tx => {
  return getTransitionsToState(STATE_SUBMITTED_TO_HAULIER).includes(txLastTransition(tx));
};

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txPurchasedPaymentByInvoice = tx =>
  getTransitionsToState(STATE_SUBMITTED_TO_SUPPLIER).includes(txLastTransition(tx));

export const txIsOnHold = tx => getTransitionsToState(STATE_ON_HOLD).includes(txLastTransition(tx));

export const txIsCancelled = tx =>
  getTransitionsToState(STATE_CANCELLED_BY_SUPPLIER).includes(txLastTransition(tx));

export const txIsCancelledBySupplier = tx =>
  getTransitionsToState(STATE_CANCELLED_BY_SUPPLIER).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

export const txIsPurchased = tx =>
  getTransitionsToState(STATE_PURCHASED).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsReceived = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsDisputed = tx =>
  getTransitionsToState(STATE_DISPUTED).includes(txLastTransition(tx));

export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsReviewedByProvider = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

// Helper function to check if the transaction has passed a certain state

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_CREATE_NEW_ACTIVE_BASKET,
    TRANSITION_ADD_LINE_ITEM_TO_ACTIVE_BASKET,
    TRANSITION_UPDATE_LINE_ITEM_QUANTITY_IN_ACTIVE_BASKET,
    TRANSITION_ADD_PURCHASE_ORDER_NUMBER,
    TRANSITION_ADD_DELIVERY_DATE,
    TRANSITION_CUSTOMER_ADD_DELIVERY_DATE,
    TRANSITION_ADD_SUPPLIER_ORDER_NUMBER,
    TRANSITION_PURCHASED_PAYMENT_BY_INVOICE,
    TRANSITION_SYSTEM_ACCEPT_ORDER,
    TRANSITION_SUBMIT_TO_SUPPLIER,
    TRANSITION_SUPPLIER_ACCEPTS_ORDER,
    TRANSITION_SUPPLIER_ACCEPTS_ORDER_WITHOUT_NOTIFICATION,
    TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_ONECLICK,
    TRANSITION_SUPPLIER_ACCEPTS_ORDER_VIA_OPERATOR,
    TRANSITION_PLACE_ON_HOLD,
    TRANSITION_REMOVE_ON_HOLD,
    TRANSITION_CANCELLED_FROM_SUBMITTED_TO_SUPPLIER,
    TRANSITION_CANCELLED_FROM_ACCEPTED_BY_SUPPLIER,
    TRANSITION_CANCELLED_FROM_ON_HOLD,
    TRANSITION_MARK_AS_PICKED,
    TRANSITION_GO_BACK_TO_ACCEPTED,
    TRANSITION_GO_BACK_TO_SUBMITTED,
    TRANSITION_GO_BACK_TO_READY_FOR_DISPATCH,
    TRANSITION_MARK_AS_DISPATCHED,
    TRANSITION_MARK_AS_DELIVERED,
    TRANSITION_UPDATE_LINE_ITEM_PICKED_DETAILS,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_CREATE_NEW_ACTIVE_BASKET,
    TRANSITION_ADD_LINE_ITEM_TO_ACTIVE_BASKET,
    TRANSITION_UPDATE_LINE_ITEM_QUANTITY_IN_ACTIVE_BASKET,
    TRANSITION_ADD_PURCHASE_ORDER_NUMBER,
  ].includes(transition);
};
