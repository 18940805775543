// Generated from donation
'use strict';

const transactionStates = {
  "offered": {
    "nextTransitions": [
      {
        "name": "transition/cancel-from-offered-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-offered-by-buyer",
        "to": "cancelled-by-buyer",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/donee-rejects-offer-from-offered",
        "to": "rejected-by-donee",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/donee-accepts-offer",
        "to": "accepted-by-donee",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/donee-accepts-offer-via-operator",
        "to": "accepted-by-donee",
        "actor": "operator",
        "userType": "operator"
      }
    ],
    "lastTransitions": []
  },
  "cancelled-by-operator": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/cancel-from-offered-by-operator",
        "from": "offered",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-accepted-by-donee-by-operator",
        "from": "accepted-by-donee",
        "actor": "operator",
        "userType": "operator"
      }
    ]
  },
  "cancelled-by-buyer": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/cancel-from-offered-by-buyer",
        "from": "offered",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "rejected-by-donee": {
    "nextTransitions": [
      {
        "name": "transition/donee-accepts-offer-from-rejected",
        "to": "accepted-by-donee",
        "actor": "provider",
        "userType": "provider"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/donee-rejects-offer-from-offered",
        "from": "offered",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "accepted-by-donee": {
    "nextTransitions": [
      {
        "name": "transition/cancel-from-accepted-by-donee-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/donee-marks-as-received",
        "to": "received",
        "actor": "provider",
        "userType": "provider"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/donee-accepts-offer",
        "from": "offered",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/donee-accepts-offer-from-rejected",
        "from": "rejected-by-donee",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/donee-accepts-offer-via-operator",
        "from": "offered",
        "actor": "operator",
        "userType": "operator"
      }
    ]
  },
  "received": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/donee-marks-as-received",
        "from": "accepted-by-donee",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  }
};

function getCommaSeperatedListOfLastTransitions(state) {
  if (!transactionStates[state]) return '';
  return transactionStates[state].lastTransitions
    .map(t => t.name)
    .join(', ');
}

function getLastTransitionNames(states) {
  // Handle both single state string and array of states
  const stateArray = Array.isArray(states) ? states : [states];
  
  return stateArray.reduce((acc, state) => {
    if (transactionStates[state]) {
      return [...acc, ...transactionStates[state].lastTransitions.map(t => t.name)];
    }
    return acc;
  }, []);
}

function getAllStates() {
  return Object.keys(transactionStates);
}

module.exports = {
  transactionStates,
  getCommaSeperatedListOfLastTransitions,
  getLastTransitionNames,
  getAllStates
};