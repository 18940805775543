// Generated index file
'use strict';

const donation = require('./donation');
const flexproductdefaultprocess = require('./flex-product-default-process');
const paymentbyinvoice = require('./payment-by-invoice');

const processes = {
  'donation': donation,
  'flex-product-default-process': flexproductdefaultprocess,
  'payment-by-invoice': paymentbyinvoice
};

function getProcess(processName) {
  return processes[processName];
}

function getAllProcessNames() {
  return Object.keys(processes);
}

function getLastTransitionsForStates(processName, states) {
  const process = getProcess(processName);
  return process ? process.getLastTransitionsForStates(states) : [];
}

module.exports = {
  processes,
  getProcess,
  getAllProcessNames,
  getLastTransitionsForStates,
  donation, flexproductdefaultprocess, paymentbyinvoice
};