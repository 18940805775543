import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS, SUPPLIER_TRANSITIONS, BUYER_TRANSITIONS } from '../../util/transaction';
import { DONOR_TRANSITIONS, DONEE_TRANSITIONS } from '../../util/donation';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import { getTransactions, getDonations } from '../../util/api';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_DONATIONS_SUCCESS = 'app/InboxPage/FETCH_DONATIONS_SUCCESS';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  donationPagination: null,
  transactionRefs: [],
  donationRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_DONATIONS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        donationRefs: entityRefs(transactions),
        donationPagination: payload.data.meta,
      };
    }

    case FETCH_ORDERS_OR_SALES_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        fetchOrdersOrSalesError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({
  type: FETCH_ORDERS_OR_SALES_REQUEST,
});
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});

const fetchDonationsSuccess = response => ({
  type: FETCH_DONATIONS_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { tab } = params;
  const isOrders = tab === 'orders';
  const isSuppliers = tab === 'suppliers';
  const isSales = tab === 'sales';
  const isDonate = tab === 'donate';
  const isReceiveDonations = tab === 'received-donations';
  const isOfferedDonation = tab === 'offered-donation';
  const isDonatorHistory = tab == 'donator-history';
  const onlyTransactionFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyTransactionsFilter = onlyTransactionFilterValues[tab];

  if (
    !isOrders &&
    !isSuppliers &&
    !isSales &&
    !isDonate &&
    !isDonatorHistory &&
    !isReceiveDonations &&
    isOfferedDonation
  ) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1, last_transition = null } = parse(search);

  const lastTransitions = last_transition || (isOrders ? BUYER_TRANSITIONS : SUPPLIER_TRANSITIONS);

  const apiTransactionQueryParams = {
    only: onlyTransactionsFilter,
    lastTransitions,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'provider.displayName',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
    search,
    isOrders,
    isSales,
  };

  let transactionRepsonse = await getTransactions(apiTransactionQueryParams);

  dispatch(addMarketplaceEntities(transactionRepsonse));
  dispatch(fetchOrdersOrSalesSuccess(transactionRepsonse));

  const apiDonationsQueryParams = {
    only: onlyTransactionsFilter,
    lastTransitions: isDonatorHistory ? DONOR_TRANSITIONS : DONEE_TRANSITIONS,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'provider.displayName',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
    search,
  };

  const donationsResponse = await getDonations(apiDonationsQueryParams);

  dispatch(addMarketplaceEntities(donationsResponse));
  dispatch(fetchDonationsSuccess(donationsResponse));

  return transactionRepsonse;
};
