// Generated from flex-product-default-process
'use strict';

const transactionStates = {
  "enquiry": {
    "nextTransitions": [
      {
        "name": "transition/request-payment-after-enquiry",
        "to": "pending-payment",
        "actor": "customer",
        "userType": "customer"
      }
    ],
    "lastTransitions": []
  },
  "pending-payment": {
    "nextTransitions": [
      {
        "name": "transition/confirm-payment",
        "to": "purchased",
        "actor": "customer",
        "userType": "customer"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/request-payment-after-enquiry",
        "from": "enquiry",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "purchased": {
    "nextTransitions": [
      {
        "name": "transition/mark-received-from-purchased",
        "to": "received",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/mark-delivered",
        "to": "delivered",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel",
        "to": "canceled",
        "actor": "operator",
        "userType": "operator"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/confirm-payment",
        "from": "pending-payment",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "received": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/mark-received-from-purchased",
        "from": "purchased",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/mark-received",
        "from": "delivered",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/mark-received-from-disputed",
        "from": "disputed",
        "actor": "operator",
        "userType": "operator"
      }
    ]
  },
  "delivered": {
    "nextTransitions": [
      {
        "name": "transition/mark-received",
        "to": "received",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/dispute",
        "to": "disputed",
        "actor": "customer",
        "userType": "customer"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/mark-delivered",
        "from": "purchased",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "disputed": {
    "nextTransitions": [
      {
        "name": "transition/mark-received-from-disputed",
        "to": "received",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-disputed",
        "to": "canceled",
        "actor": "operator",
        "userType": "operator"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/dispute",
        "from": "delivered",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "canceled": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/cancel",
        "from": "purchased",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-disputed",
        "from": "disputed",
        "actor": "operator",
        "userType": "operator"
      }
    ]
  },
  "completed": {
    "nextTransitions": [
      {
        "name": "transition/review-1-by-provider",
        "to": "reviewed-by-provider",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/review-1-by-customer",
        "to": "reviewed-by-customer",
        "actor": "customer",
        "userType": "customer"
      }
    ],
    "lastTransitions": []
  },
  "reviewed-by-provider": {
    "nextTransitions": [
      {
        "name": "transition/review-2-by-customer",
        "to": "reviewed",
        "actor": "customer",
        "userType": "customer"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/review-1-by-provider",
        "from": "completed",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "reviewed-by-customer": {
    "nextTransitions": [
      {
        "name": "transition/review-2-by-provider",
        "to": "reviewed",
        "actor": "provider",
        "userType": "provider"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/review-1-by-customer",
        "from": "completed",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "reviewed": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/review-2-by-provider",
        "from": "reviewed-by-customer",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/review-2-by-customer",
        "from": "reviewed-by-provider",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  }
};

function getCommaSeperatedListOfLastTransitions(state) {
  if (!transactionStates[state]) return '';
  return transactionStates[state].lastTransitions
    .map(t => t.name)
    .join(', ');
}

function getLastTransitionNames(states) {
  // Handle both single state string and array of states
  const stateArray = Array.isArray(states) ? states : [states];
  
  return stateArray.reduce((acc, state) => {
    if (transactionStates[state]) {
      return [...acc, ...transactionStates[state].lastTransitions.map(t => t.name)];
    }
    return acc;
  }, []);
}

function getAllStates() {
  return Object.keys(transactionStates);
}

module.exports = {
  transactionStates,
  getCommaSeperatedListOfLastTransitions,
  getLastTransitionNames,
  getAllStates
};