// Generated from payment-by-invoice
'use strict';

const transactionStates = {
  "basket-ready": {
    "nextTransitions": [
      {
        "name": "transition/add-line-item-to-active-basket",
        "to": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/update-line-item-quantity-in-active-basket",
        "to": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/add-purchase-order-number",
        "to": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/cancel-from-basket-ready-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-empty-basket-from-basket-ready-by-operator",
        "to": "cancelled-by-operator",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/cancel-from-basket-ready-by-buyer",
        "to": "cancelled-by-buyer",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/purchased-payment-by-invoice",
        "to": "submitted-for-validation",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/customer-add-delivery-date",
        "to": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/add-line-item-to-active-basket",
        "from": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/update-line-item-quantity-in-active-basket",
        "from": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/add-purchase-order-number",
        "from": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/customer-add-delivery-date",
        "from": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "cancelled-by-operator": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/cancel-from-basket-ready-by-operator",
        "from": "basket-ready",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-empty-basket-from-basket-ready-by-operator",
        "from": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/cancel-from-submitted-for-validation-by-operator",
        "from": "submitted-for-validation",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-accepted-by-system-by-operator",
        "from": "accepted-by-system",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-submitted-to-supplier-by-operator",
        "from": "submitted-to-supplier",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-accepted-by-supplier-by-operator",
        "from": "accepted-by-supplier",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-ready-for-dispatch-by-operator",
        "from": "ready-for-dispatch",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-dispatched-by-operator",
        "from": "dispatched",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-delivered-by-operator",
        "from": "delivered",
        "actor": "operator",
        "userType": "operator"
      }
    ]
  },
  "cancelled-by-buyer": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/cancel-from-basket-ready-by-buyer",
        "from": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "submitted-for-validation": {
    "nextTransitions": [
      {
        "name": "transition/system-accept-order",
        "to": "accepted-by-system",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/cancel-from-submitted-for-validation-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/purchased-payment-by-invoice",
        "from": "basket-ready",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "accepted-by-system": {
    "nextTransitions": [
      {
        "name": "transition/submit-to-supplier",
        "to": "submitted-to-supplier",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/cancel-from-accepted-by-system-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/system-accept-order",
        "from": "submitted-for-validation",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  },
  "submitted-to-supplier": {
    "nextTransitions": [
      {
        "name": "transition/supplier-accepts-order",
        "to": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/supplier-accepts-order-without-notification",
        "to": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel-from-submitted-to-supplier",
        "to": "cancelled-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/place-on-hold",
        "to": "on-hold",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/supplier-accepts-order-via-operator",
        "to": "accepted-by-supplier",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/supplier-accepts-order-via-oneclick",
        "to": "accepted-by-supplier",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-submitted-to-supplier-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/add-supplier-order-number",
        "to": "submitted-to-supplier",
        "actor": "provider",
        "userType": "provider"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/submit-to-supplier",
        "from": "accepted-by-system",
        "actor": "customer",
        "userType": "customer"
      },
      {
        "name": "transition/go-back-to-submitted",
        "from": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/add-supplier-order-number",
        "from": "submitted-to-supplier",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "accepted-by-supplier": {
    "nextTransitions": [
      {
        "name": "transition/go-back-to-submitted",
        "to": "submitted-to-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/add-delivery-date",
        "to": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/update-line-item-picked-details",
        "to": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel-from-accepted-by-supplier-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/mark-as-picked",
        "to": "ready-for-dispatch",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel-from-accepted-by-supplier",
        "to": "cancelled-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/add-invoice-number-and-file",
        "to": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/supplier-accepts-order",
        "from": "submitted-to-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/supplier-accepts-order-without-notification",
        "from": "submitted-to-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/remove-on-hold",
        "from": "on-hold",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/supplier-accepts-order-via-operator",
        "from": "submitted-to-supplier",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/supplier-accepts-order-via-oneclick",
        "from": "submitted-to-supplier",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/add-delivery-date",
        "from": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/update-line-item-picked-details",
        "from": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/go-back-to-accepted",
        "from": "ready-for-dispatch",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/add-invoice-number-and-file",
        "from": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "cancelled-by-supplier": {
    "nextTransitions": [],
    "lastTransitions": [
      {
        "name": "transition/cancel-from-submitted-to-supplier",
        "from": "submitted-to-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel-from-on-hold",
        "from": "on-hold",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel-from-on-hold-by-operator",
        "from": "on-hold",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/cancel-from-accepted-by-supplier",
        "from": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "on-hold": {
    "nextTransitions": [
      {
        "name": "transition/cancel-from-on-hold",
        "to": "cancelled-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel-from-on-hold-by-operator",
        "to": "cancelled-by-supplier",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/remove-on-hold",
        "to": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/place-on-hold",
        "from": "submitted-to-supplier",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "ready-for-dispatch": {
    "nextTransitions": [
      {
        "name": "transition/go-back-to-accepted",
        "to": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel-from-ready-for-dispatch-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/add-transport-details",
        "to": "ready-for-dispatch",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/mark-as-dispatched",
        "to": "dispatched",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/supplier-send-to-haulier",
        "to": "submitted-to-haulier",
        "actor": "provider",
        "userType": "provider"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/mark-as-picked",
        "from": "accepted-by-supplier",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/go-back-to-ready-for-dispatch",
        "from": "dispatched",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/add-transport-details",
        "from": "ready-for-dispatch",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "dispatched": {
    "nextTransitions": [
      {
        "name": "transition/go-back-to-ready-for-dispatch",
        "to": "ready-for-dispatch",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/cancel-from-dispatched-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      },
      {
        "name": "transition/mark-as-delivered",
        "to": "delivered",
        "actor": "customer",
        "userType": "customer"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/mark-as-dispatched",
        "from": "ready-for-dispatch",
        "actor": "provider",
        "userType": "provider"
      },
      {
        "name": "transition/haulier-accepts-order-via-oneclick-by-operator",
        "from": "submitted-to-haulier",
        "actor": "operator",
        "userType": "operator"
      }
    ]
  },
  "submitted-to-haulier": {
    "nextTransitions": [
      {
        "name": "transition/haulier-accepts-order-via-oneclick-by-operator",
        "to": "dispatched",
        "actor": "operator",
        "userType": "operator"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/supplier-send-to-haulier",
        "from": "ready-for-dispatch",
        "actor": "provider",
        "userType": "provider"
      }
    ]
  },
  "delivered": {
    "nextTransitions": [
      {
        "name": "transition/cancel-from-delivered-by-operator",
        "to": "cancelled-by-operator",
        "actor": "operator",
        "userType": "operator"
      }
    ],
    "lastTransitions": [
      {
        "name": "transition/mark-as-delivered",
        "from": "dispatched",
        "actor": "customer",
        "userType": "customer"
      }
    ]
  }
};

function getCommaSeperatedListOfLastTransitions(state) {
  if (!transactionStates[state]) return '';
  return transactionStates[state].lastTransitions
    .map(t => t.name)
    .join(', ');
}

function getLastTransitionNames(states) {
  // Handle both single state string and array of states
  const stateArray = Array.isArray(states) ? states : [states];
  
  return stateArray.reduce((acc, state) => {
    if (transactionStates[state]) {
      return [...acc, ...transactionStates[state].lastTransitions.map(t => t.name)];
    }
    return acc;
  }, []);
}

function getAllStates() {
  return Object.keys(transactionStates);
}

module.exports = {
  transactionStates,
  getCommaSeperatedListOfLastTransitions,
  getLastTransitionNames,
  getAllStates
};