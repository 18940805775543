import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { TabNav, UnreadMessagesCounter, NotificationBadge } from '..';
import { parse } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import css from './SideNavigation.module.css';

const  { getProcess } = require('@platter/transaction-state');
const transactionProcess = getProcess('payment-by-invoice');
const SideNavigation = props => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    providerNotificationCount,
    currentUserPermissions,
    ordersOnHold,
    isSuppliers,
    isCustomers,
    isActiveCustomers,
    isInvites,
    isOrders,
    isSales,
    isYourListings,
    setIsInviteSupplierModalOpen,
    setIsInviteBuyerModalOpen,
    isMessaging,
    orderCounts,
    transactionCounts,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const sideTabs = [];

  const exisitingStatusFilters = {};
  const searchParams = location.search ? parse(location.search) : null;
  const sid = searchParams && searchParams?.sid ? parse(location.search).sid : null;
  const UnreadMessagesCount = <UnreadMessagesCounter></UnreadMessagesCounter>;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;

  const totalNotificationCount = _.find(transactionCounts, item => item.attributes.type === 'all')
    ?.attributes?.count;
  const totalNotificationBadge =
    totalNotificationCount > 0 ? (
      <NotificationBadge className={css.subNav} count={totalNotificationCount} />
    ) : null;

  const awaitingApprovalNotificationCount = _.find(
    transactionCounts,
    item => item.attributes.type === 'awaitingApproval'
  )?.attributes?.count;

  const awaitingApprovalNotificationBadge =
    awaitingApprovalNotificationCount > 0 ? (
      <NotificationBadge className={css.subNav} count={awaitingApprovalNotificationCount} />
    ) : null;

  const approvedNotificationCount = _.find(
    transactionCounts,
    item => item.attributes.type === 'approved'
  )?.attributes?.count;

  const approvedNotificationBadge =
    approvedNotificationCount > 0 ? (
      <NotificationBadge className={css.subNav} count={approvedNotificationCount} />
    ) : null;

  const awaitingDispatchNotificationCount = _.find(
    transactionCounts,
    item => item.attributes.type === 'awaitingDispatch'
  )?.attributes?.count;

  const awaitingDispatchNotificationBadge =
    awaitingDispatchNotificationCount > 0 ? (
      <NotificationBadge className={css.subNav} count={awaitingDispatchNotificationCount} />
    ) : null;

  const inTransitNotificationCount = _.find(
    transactionCounts,
    item => item.attributes.type === 'inTransit'
  )?.attributes?.count;

  const inTransitNotificationBadge =
    inTransitNotificationCount > 0 ? (
      <NotificationBadge className={css.subNav} count={inTransitNotificationCount} />
    ) : null;

  const onHoldNotificationCount = _.find(
    transactionCounts,
    item => item.attributes.type === 'onHold'
  )?.attributes?.count;

  const onHoldNotificationBadge =
    onHoldNotificationCount > 0 ? (
      <NotificationBadge className={css.subNav} count={onHoldNotificationCount} />
    ) : null;

  if (sid) {
    exisitingStatusFilters['sid'] = sid;
  }

  if (currentUserPermissions?.buying?.isBuyer) {
    const statusTabs = [];
    const supplierTabs = [];
    supplierTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'suppliers' },
        },
      },
      {
        text: <span class={css.onHoldSubNav}>Add Supplier</span>,

        handleClick: () => {
          setIsInviteSupplierModalOpen(true);
        },
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'suppliers' },
        },
      }
    );

    statusTabs.push({
      text: <span>All</span>,
      linkProps: {
        name: 'InboxPage', 
        params: { tab: 'orders', dataCyId: 'all' },
        to: {
          search: `?${new URLSearchParams({
            last_transition: transactionProcess.getLastTransitionNames(['basket-ready','submitted-to-supplier','accepted-by-supplier','on-hold','ready-for-dispatch','dispatched', 'submitted-to-haulier', 'delivered']).join(','),
            ...exisitingStatusFilters,
          })}`,
        },
      }
    }, {
      text: <span class={css.onHoldSubNav}>On Hold {onHoldNotificationBadge}</span>,
      visible: ordersOnHold.length > 0,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders', dataCyId: 'on-hold' },
        to: {
          search: `?${new URLSearchParams({
            last_transition: transactionProcess.getLastTransitionNames('on-hold').join(','),
            ...exisitingStatusFilters, 
          })}`,
        },
      }
    }, {
      text: <span>Active Basket</span>,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders', dataCyId: 'active-baskets' },
        to: {
          search: `?${new URLSearchParams({
            last_transition: transactionProcess.getLastTransitionNames('basket-ready').join(','),
            ...exisitingStatusFilters,
          })}`,
        },
      }
    }, {
      text: <span>Awaiting Approval{awaitingApprovalNotificationBadge}</span>,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders', dataCyId: 'awaiting-approval' },
        to: {
          search: `?${new URLSearchParams({
            last_transition: transactionProcess.getLastTransitionNames('submitted-to-supplier').join(','),
            ...exisitingStatusFilters,
          })}`,
        },
      }
    }, {
      text: <span>Approved Orders{approvedNotificationBadge}</span>,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders', dataCyId: 'approved' },
        to: {
          search: `?${new URLSearchParams({
            last_transition: transactionProcess.getLastTransitionNames('accepted-by-supplier').join(','),
        
            ...exisitingStatusFilters,
          })}`,
        },
      }
    }, {
      text: <span>Awaiting dispatch{awaitingDispatchNotificationBadge}</span>,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders', dataCyId: 'ready-for-dispatch' },
        to: {
          search: `?${new URLSearchParams({
            last_transition: transactionProcess.getLastTransitionNames('ready-for-dispatch').join(','),
            ...exisitingStatusFilters,
          })}`,
        },
      }
    }, {
      text: <span>In Transit{inTransitNotificationBadge}</span>,
      linkProps: {
        name: 'InboxPage', 
        params: { tab: 'orders', dataCyId: 'in-transit' },
        to: {
          search: `?${new URLSearchParams({
            last_transition: transactionProcess.getLastTransitionNames('dispatched').join(','),
            ...exisitingStatusFilters,
          })}`,
        },
      }
    }, {
      text: <span>Completed</span>,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders', dataCyId: 'complete' },
        to: {
          search: `?${new URLSearchParams({
            last_transition: transactionProcess.getLastTransitionNames('delivered').join(','),
            ...exisitingStatusFilters,
          })}`,
        },
      }
    });

    sideTabs.push(
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.mySuppliersTitle" />
          </span>
        ),
        selected: isSuppliers,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'suppliers' },
        },

        subNav: supplierTabs,
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.ordersTabTitle" />
            {totalNotificationBadge}
          </span>
        ),
        selected: isOrders,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders', dataCyId: 'all' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames(['basket-ready','submitted-to-supplier','accepted-by-supplier','on-hold','ready-for-dispatch','dispatched', 'submitted-to-haulier', 'delivered']).join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },
        subNav: statusTabs,
      }
    );
  }
  if (currentUserPermissions?.selling?.isSeller) {
    const statusTabs = [];

    const customersTabs = [];
    customersTabs.push(
      {
        text: <span>Active</span>,
        selected: isActiveCustomers,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'active-customers' },
        },
      },
      {
        text: <span>Not Activated</span>,
        selected: isInvites,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'invites' },
        },
      },

      {
        text: <span class={css.onHoldSubNav}>Add New Buyer</span>,

        handleClick: () => {
          setIsInviteBuyerModalOpen(true);
        },
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'customers' },
        },
      }
    );

    statusTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'all' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames('delivered').join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>New{awaitingApprovalNotificationBadge}</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'new' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames('submitted-to-supplier').join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>Approved{approvedNotificationBadge}</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'approved' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames('accepted-by-supplier').join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span class={css.onHoldSubNav}>On Hold {onHoldNotificationBadge}</span>,
        visible: ordersOnHold.length > 0,
        linkProps: {
          name: 'InboxPage',

          params: { tab: 'sales', dataCyId: 'on-hold' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames('on-hold').join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Ready to dispatch{awaitingDispatchNotificationBadge}</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'ready-for-dispatch' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames('ready-for-dispatch').join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },

      {
        text: <span>In Transit{inTransitNotificationBadge}</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'in-transit' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames('dispatched').join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Completed</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'complete' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames('delivered').join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },
      }
    );

    sideTabs.push(
      {
        text: <span>Customers</span>,
        selected: isCustomers,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'customers' },
        },
        subNav: customersTabs,
      },

      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.salesTabTitle" />
            {totalNotificationBadge}
          </span>
        ),
        selected: isSales,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales', dataCyId: 'all' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: transactionProcess.getLastTransitionNames(['basket-ready','submitted-to-supplier','accepted-by-supplier','on-hold','ready-for-dispatch','dispatched', 'submitted-to-haulier', 'delivered']).join(','),
              ...exisitingStatusFilters,
            })}`,
          },
        },

        subNav: statusTabs,
      },
      {
        text: (
          <span>
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </span>
        ),
        selected: isYourListings,
        linkProps: {
          name: 'SearchPage',
          params: { tab: 'your-listing' },
        },
      }
    );
  }
  if (currentUserPermissions?.donating?.isDonor) {
    const donationStatusTabs = [];
    donationStatusTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/send-new-donation,transition/donee-rejects-offer-from-offered,transition/donee-accepts-offer,transition/donee-accepts-offer-from-rejected,transition/donee-accepts-offer-via-operator,transition/donee-marks-as-received',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>New</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/send-new-donation',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Accepted</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/donee-accepts-offer,transition/donee-accepts-offer-via-operator',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Rejected</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/donee-rejects-offer-from-offered',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Completed</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'donator-history' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/donee-marks-as-received',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      }
    );

    sideTabs.push({
      text: (
        <span>
          <FormattedMessage id="InboxPage.donateTabTitle" />
        </span>
      ),
      selected: false,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'donate' },
        to: {
          search: `?${new URLSearchParams({
            last_transition:
              'transition/send-new-donation,transition/donee-rejects-offer-from-offered,transition/donee-accepts-offer,transition/donee-accepts-offer-from-rejected,transition/donee-accepts-offer-via-operator,transition/donee-marks-as-received',
            ...exisitingStatusFilters,
          })}`,
        },
      },
      subNav: donationStatusTabs,
    });
  }
  if (currentUserPermissions?.donationReceiving?.isDonee) {
    const donationStatusTabs = [];
    donationStatusTabs.push(
      {
        text: <span>All</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/send-new-donation,transition/donee-rejects-offer-from-offered,transition/donee-accepts-offer,transition/donee-accepts-offer-from-rejected,transition/donee-accepts-offer-via-operator,transition/donee-marks-as-received',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>New</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/send-new-donation',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Accepted</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition:
                'transition/donee-accepts-offer,transition/donee-accepts-offer-via-operator',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Rejected</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/donee-rejects-offer-from-offered',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      },
      {
        text: <span>Completed</span>,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'received-donations' },
          to: {
            search: `?${new URLSearchParams({
              last_transition: 'transition/donee-marks-as-received',
              ...exisitingStatusFilters,
            })}`,
          },
        },
      }
    );
    sideTabs.push({
      text: (
        <span>
          <FormattedMessage id="InboxPage.donateTabTitle" />
        </span>
      ),
      selected: false,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'received-donations' },
        to: {
          search: `?${new URLSearchParams({
            last_transition:
              'transition/send-new-donation,transition/donee-rejects-offer-from-offered,transition/donee-accepts-offer,transition/donee-accepts-offer-from-rejected,transition/donee-accepts-offer-via-operator,transition/donee-marks-as-received',
            ...exisitingStatusFilters,
          })}`,
        },
      },

      subNav: donationStatusTabs,
    });
  }
  // sideTabs.push({
  //   text: <span>Messages {UnreadMessagesCount}</span>,
  //   selected: isMessaging,
  //   linkProps: {
  //     name: 'MessagingPage',
  //     params: { tab: 'messages' },
  //   },
  // });
  return <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={sideTabs} />;
};

SideNavigation.defaultProps = {
  className: null,
  rootClassName: null,
  containerClassName: null,
};

const { node, string } = PropTypes;

SideNavigation.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
};

export default SideNavigation;